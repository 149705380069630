
function contactSuccess(){
    notifications.show("El formulario se ha enviado correctamente. En breve contactaremos contigo.", 
    notifications.statuses.SUCCESS, function() {
        location.reload();
    });
}

function contactError(response){
    $('form').find('.form-group--error').removeClass('form-group--error');

    jQuery.each(response.specificData, function( i, field ) {
        $('#'+ field).parent().addClass('form-group--error');
    });
}
