var notifications = function($template, customSettings) {

	var statuses = {
		 'INFO': 'info'
		,'SUCCESS': 'success'
		,'WARNING': 'warning'
		,'DANGER': 'danger'
	};

	var defaultSettings = {
			 'autoclose': true
			,'autoclose_time': 6000
			,'buttonClose': true
		} 
        ,settings = $.extend({}, defaultSettings, customSettings)
		,timeout
		,_this = this
		,$el = null
		,opened = false
		,callback;


	var getEl = function() {
		var $elem = $el || $($template.html());

		if(settings.buttonClose) {
			$elem.find('.m-alert__close, .modal-close').off('click').on('click', function(e) {
				e.preventDefault();

				if(timeout) clearTimeout(timeout);
				hide();

				return false;
			});
		} else {
			$elem.find('.m-alert__close, .modal-close').remove();
		}

		return $elem;
	};

	// status: [success|warning|danger|info]
	var show = function(message, status, cb, customSettings) {
		status = status || statuses.INFO;

		var  oldSettings = $.extend(true, {}, settings)
			,title = ''
			,classes = 'm-alert--' + status;

		settings = $.extend(true, {}, oldSettings, customSettings)

		if("object" === typeof message){
			title = message.title;
			message = message.message;
		}
		if("undefined" !== typeof settings.extraClass) {
			classes += " " + settings.extraClass;
		}
		callback = cb;

		$el = getEl();
		
		$el
			.find(".m-alert__text").html(message);
		$el
			.find(".m-alert__title").html(title);
		$el
			.appendTo($('body'))
			.removeClass('m-alert--' + statuses.INFO + ' m-alert--' + statuses.SUCCESS + 'm-alert--' + statuses.WARNING + 'm-alert--' + statuses.DANGER)
			.addClass(classes)
			.removeClass('fadeOut')
			.addClass('fadeIn')
			.css('visibility', 'visible');

		//initializer.processQueue($el.find(".m-alert__text"));
		$("body, html").addClass('lock-position');

		if(settings.autoclose) {
			if(timeout) clearTimeout(timeout);
			timeout = setTimeout(function() {
				hide();
			}, settings.autoclose_time);
		}

		settings = oldSettings;

		opened = true;

	};

	var hide = function() {
		if(!opened) return;

		if(timeout) clearTimeout(timeout);
		timeout = setTimeout(function() {
			$el.fadeOut(function() {
				$("body, html").removeClass('lock-position');
				this.remove();
				$el = null;

				if (typeof callback === "function") {
	                callback();
	            }	
			})
		}, 400);

		opened = false;
	};

	var isOpened = function() {
		return opened;
	}

	return {
		 'statuses' : statuses
		,'show' : show
		,'hide' : hide
		,'isOpened' : isOpened
	};

}($("#notification"));