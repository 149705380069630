(function($) {
    var processingClass = "processing";

    var getResponseType = function(jqXHR) {
        var  ct = jqXHR.getResponseHeader("content-type") || ""
            ,type;
        if(ct.indexOf("html") > -1) {
          type = "html";
        } else if(ct.indexOf("json") > -1) {
          type = "json";
        }
        return type;
    };
    // $btn = element that triggered the request
    // type = [link|form]
    // $el  = element that's been sent (form or link)
    // Si type == 'link', entonces $btn == $el
    var toggleButton = function($btn, type, $el) {
        if(!$btn.length) return;
        
        var  val = (type == 'link' || $btn.get(0).nodeName == 'BUTTON') ? $btn.html() : $btn.val()
            ,valOn = $btn.data('valueOn')
            ,processing = $el.hasClass(processingClass);

        $btn.blur();
        if(!processing) {
            $el.addClass(processingClass);
            $btn.addClass(processingClass)
                .prop("disabled", true);
                
            if(valOn) {
                $btn.data('value',val);
                if(type == 'link' || $btn.get(0).nodeName == 'BUTTON') {
                    $btn.html(valOn);
                } else {
                    $btn.val(valOn);
                }
            }
        } else {
            $el.removeClass(processingClass);
            $btn
                .removeClass(processingClass)
                .prop("disabled", false);

            if(valOn) {
                if(type == 'link' || $btn.get(0).nodeName == 'BUTTON') {
                    $btn.html($btn.data('value'));
                } else {
                    $btn.val($btn.data('value'));
                }
            }
        }
    };
    // Asynchronous links and forms
    $.fn.async = function(options) {

        return this.each(function() {
            var  $el = $(this)
                ,ev = ($el[0].tagName=='FORM') ? 'submit' : 'click'
                ,validate = (ev=='submit' && $el.hasClass('validate'));

            var doRequest = function($el, type, method, $btn, processing) {
                $el.find('.error').removeClass('error');
                $el.find('.error-msg').empty().hide();
                
                var opt = {
                     type: method
                    ,url: (type == 'link') ? $el.attr('href') : $el.attr('action')
                    ,data: getDataForAjax($el, type)
                    ,beforeSend:function(jqXHR, settings) {}
                };

                if($el.data('upload')) {
                    opt.processData = false;
                    opt.contentType = false;
                }

                $_d.trigger('engine.ajax:init',[$el]);
                $el.trigger('engine.ajax:init');
                $el.removeClass('ajax-has-errors');

                $.ajax(opt)
                    .success(function(data, textStatus, jqXHR) {
                        var dataType = getResponseType(jqXHR);

                        $_d.trigger('engine.ajax:success',[$el, data, dataType]);
                        $el.trigger('engine.ajax:success',[data, dataType]);

                        if (typeof window[$el.data('callback')] === "function") {
                            window[$el.data('callback')](data, $el, dataType);
                        }
                    })
                    .error(function(jqXHR, ajaxOptions, thrownError){
                        var  dataType = getResponseType(jqXHR)
                            ,response = (dataType == 'json') ? $.parseJSON(jqXHR.responseText) : jqXHR.responseText;

                        $_d.trigger('engine.ajax:error',[$el, response, dataType]);
                        $el.trigger('engine.ajax:error',[response, dataType]);

                        if (typeof window[$el.data('callbackError')] === "function") {
                            window[$el.data('callbackError')](response, $el, dataType);
                        } else {
                            $el.find('.error-msg').html(response.response).css('display', 'block');
                        }
                        $el.addClass('ajax-has-errors');
                    })
                    .complete(function(jqXHR, ajaxOptions, thrownError){
                        $_d.trigger('engine.ajax:complete',[$el]);
                        $el.trigger('engine.ajax:complete');

                        toggleButton($btn, type, $el);
                    });
            };

            $el.on(ev,function(e){
                e.preventDefault();
            
                var  ok = true
                    ,$el = $(this)
                    ,type = (e.type=="click") ? 'link' : 'form'
                    ,method = (type=='form') ? $el.attr('method') : (typeof $el.data('method')!='undefined' ? $el.data('method') : 'GET')
                    ,$btn = (type=='link') ? $el : $el.find(":submit")
                    ,processing = $el.hasClass(processingClass);

                if(processing || $btn.attr("disabled") == "disabled") {
                    return false;
                }

                toggleButton($btn, type, $el);

                if(ok && validate) {
                    ok = (typeof $el.data('validation-func') != 'undefined') ? window[$el.data('validation-func')]($el) : $el.valid();
                }

                if(ok) {
                    if(typeof $el.data('confirm') !== 'undefined') {

                        if(typeof confirmation !== 'undefined') {
                            confirmation.ask($el.data('confirmTitle'), $el.data('confirm'), function() {
                                doRequest($el, type, method, $btn, processing);
                            }, function() { toggleButton($btn, type, $el); });
                        } else  {
                            if(confirm($el.data('confirm'))) {
                                doRequest($el, type, method, $btn, processing);
                            } else {
                                toggleButton($btn, type, $el);
                            }
                        }
                    } else {
                        doRequest($el, type, method, $btn, processing);
                    }
                } else {
                    toggleButton($btn, type, $el);
                }
                return false;
            });

            if(validate && typeof $el.data('validation-func') == 'undefined') {
                $el.validate({
                     errorLabelContainer: $el.find('.error-msg')
                    ,showErrors: function(errorMap, errorList) {
                        if(errorList.length) {
                            this.labelContainer.text($(this.currentForm).data('msg-required'));
                            $.each(errorList, function(i,error) {
                                $(error.element).addClass("error").parent('label').addClass('error');
                            });
                        }
                    }
                    ,debug:true
                });
            }
        });
    };

    // Asynchronous load of HTML into a target element
    $.fn.asyncLoad = function() {
        return this.each(function() {
            var  $this = $(this);

            $this.on("click.asyncLoad",function(e){
                if($this.hasClass(processingClass)) return false;

                var  $target = $($this.data('target'))
                    ,processing = $this.hasClass(processingClass)
                    ,opt = {
                         url: $this.attr("href")
                        ,data: $.param($this.data())
                        ,beforeSend:function(jqXHR, settings) {
                            toggleButton($this, 'link', $this);
                        }
                    };

                $.ajax(opt)
                    .success(function(data, textStatus, jqXHR) {
                        var dataType = getResponseType(jqXHR);
                        
                        $target.html(data);

                        if(typeof initializer !== 'undefined') {
                            initializer.processQueue($target);
                        }
                        if (typeof window[$this.data('callback')] === "function") {
                            window[$this.data('callback')](data, $this, dataType);
                        }
                    })
                    .error(function(jqXHR, ajaxOptions, thrownError){
                        var  dataType = getResponseType(jqXHR)
                            ,response = (dataType == 'json') ? $.parseJSON(jqXHR.responseText) : jqXHR.responseText;
                        if (typeof window[$this.data('callbackError')] === "function") {
                            window[$this.data('callbackError')](response, $this, dataType);
                        }
                    })
                    .complete(function(jqXHR, ajaxOptions, thrownError){
                        toggleButton($this, 'link', $this);
                    });

                return false;
            });
        });
    };
}(jQuery));

var getDataForAjax = function($el, type) {
    
    var data = {};
    var dataOpt;

    // Formularios
    if(type == 'form'){
        
        // Es un formulario con un campo de tipo file
        if($el.data('upload')) {
            var formData = new FormData($el.get(0));

            $.each($el.data(), function (index, value) {
               formData.append(index, value);
            });

            dataOpt = formData;

        // Es un formulario estándar
        } else { 

            // Fix para el problema de jQuery con el envío de checkboxes multiselect por AJAX
            var  formData = $el.serializeArray()
                ,checkboxesData = [];
            
            $(formData).each(function() {
                var isArray = (this.name.indexOf('[]') > 0);
                this.name = this.name.replace('[]','');
                if(typeof data[this.name] === 'undefined') {
                    data[this.name] = (isArray) ? [this.value] : this.value;
                } else if(isArray) {
                    data[this.name].push(this.value);
                }
            })

            data = $.extend($el.data(), data);
            dataOpt = $.param(data);
        }

    // Enlaces
    } else {

        data = $el.data();
        dataOpt = $.param(data);

    }

    return dataOpt;
};