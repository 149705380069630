var  $_d = $(document)
	,$_b = $('body')
	,$viewScope = $_d
    ,isMobile = $_b.hasClass('mobile')
    ,isDesktop = $_b.hasClass('desktop')
    ,isTablet = $_b.hasClass('tablet')
    ,isIE = $_b.hasClass('IE')
    ,isSafari = $_b.hasClass('safari')
    ,isFirefox = $_b.hasClass('firefox')
    ,isTouchDevice = (isMobile || isTablet)
    ,timeouts = {}, intervals = {}
    ,isSPA = false
;